.custom-footer {
    background: linear-gradient(to bottom, #d8232a, #6b1b1b 50%, #d8232a 100%);
    color: white;
    padding: 10px 20px 20px 40px;
    text-align: left;
    font-family: Arial, sans-serif;
    font-size: 10px;
    z-index: 1;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-text {
    max-width: 70%;
  }
  
  .footer-contact .phone-number {
    font-size: 20px;
    font-weight: bold;
  }
  
  .footer-contact img {
    vertical-align: middle;
    margin-left: 10px;
  }
  
  .footer-address {
    margin-bottom: 10px;
    font-size: 12px;
  }
  
  .footer-address div {
    display: block;
  }
  