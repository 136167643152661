.form {
  padding: 24px;
  /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 6px; */
  margin: 0px 0px 100px 30px;
  align-items: center;
  height: 100%;
  justify-content: center;
  /* background-color: red; */
}

.header {
  margin-bottom: 24px;
}

.footer {
  text-align: center;
}
.imgLogin {
  object-fit: cover;
  width: 100%;
  height: 100%;
}